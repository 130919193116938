<template>
  <v-container>
    <v-card class="pa-0">
      <v-card-title>
        Object List
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
          class="pa-0"
        ></v-text-field>
      </v-card-title>
      <v-divider></v-divider>
      <v-data-table
        dense
        :headers="headers"
        :search="search"
        :items="items.data"
        :server-items-length="items.total"
        :options.sync="pagination"
        :footer-props="{
          'items-per-page-options': [5, 10, 20, 30, 50, 100, -1],
        }"
        :loading="loading"
        item-key="name"
        class="elevation-1"
      >
        <template v-slot:item.file_url="{ item }">
          <v-btn
            v-if="item.type == 'IMAGE'"
            color="grey darken-2 px-2"
            small
            dark
            @click="showPreview(item)"
          >
            <v-icon class="pr-2">mdi-image-area</v-icon> View
          </v-btn>
          <v-btn
            v-if="item.type == 'VIDEO'"
            color="grey darken-2 px-2"
            small
            dark
            @click="showPreview(item)"
          >
            <v-icon class="pr-2">mdi-play</v-icon> Play
          </v-btn>
          <v-btn
            v-if="item.type == 'PDF'"
            color="grey darken-2 px-2"
            small
            dark
            @click="showPreview(item)"
          >
            <v-icon class="pr-2">mdi-file-pdf-box</v-icon> View
          </v-btn>
        </template>
        <template v-slot:item.link="{ item }">
          <v-btn
            color="grey darken-2"
            small
            dark
            :href="item.link"
            target="_blank"
            v-if="item.link"
          >
            Open link
          </v-btn>
          <span v-else class="align-center items-center"> - </span>
        </template>
        <template v-slot:item.action="{ item }">
          <v-btn
            color="grey darken-2"
            icon
            dark
            :to="'/object/edit/' + item.object_id"
          >
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
          <v-btn
            color="red"
            icon
            dark
            @click="
              dialog = true;
              object_id = item.object_id;
            "
          >
            <v-icon>mdi mdi-delete-outline</v-icon>
          </v-btn>
        </template>
      </v-data-table>
      <v-dialog v-model="dialog" persistent max-width="290">
        <v-card>
          <v-card-text class="pt-5"> Are you sure delete Object ? </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="grey darken-4"
              text
              @click="
                dialog = false;
                object_id = '';
              "
              >Cancel</v-btn
            >
            <v-btn color="red darken-4" text @click="deleteItem">Delete</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card>

    <v-btn color="primary" fixed fab bottom right to="/object/add">
      <v-icon>mdi-plus</v-icon>
    </v-btn>
    <!-- Video play -->
    <v-dialog v-model="preview_data" max-width="400" v-if="show_preview">
      <v-card>
        <v-card-text class="pt-2 rounded-lg px-0">
          <video
            v-if="preview_data.type == 'VIDEO'"
            :src="preview_data.upload_url"
            width="100%"
            controls
          ></video>
          <v-img
            v-if="preview_data.type == 'IMAGE'"
            :src="preview_data.upload_url"
            contain
            max-height="300px"
          ></v-img>
          <iframe
            v-if="preview_data.type == 'PDF'"
            :src="preview_data.upload_url + '#view=fitH&toolbar=0'"
            width="100%"
            height="300px"
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="grey darken-4" text @click="show_preview = false"
            >Close</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import { parseParams, getAlphabets } from "../../plugins/helper";
export default {
  name: "Objectlist",
  data() {
    return {
      preview_data: null,
      show_preview: false,
      object_id: "",
      loading: false,
      dialog: false,
      object_data: {
        position_name: null,
        page_name: null,
      },
      search: "",
      items: {
        current_page: 1,
        per_page: 50,
        data: [],
      },
      headers: [
        { text: "Page Name", value: "page_name" },
        { text: "Content Postion", value: "position_name" },
        { text: "Image/Video", value: "file_url", sortable: false },
        { text: "Link", value: "link", sortable: false, align: "center" },
        { text: "Action", value: "action", sortable: false },
      ],
      pagination: {
        itemsPerPage: 50,
        sortBy: ["created_at"],
        page: 1,
        filters: {
          item_active: true,
          alphabet: "",
        },
      },
      alphabets: getAlphabets(),
    };
  },
  computed: {
    pageData() {
      let paginationData = this.pagination;
      paginationData.search = this.search;
      paginationData.searchOption = "P.display_name";
      return paginationData;
    },
    getStyles() {
      if (this.$vuetify.breakpoint.xsOnly) {
        return {
          maxWidth: "95%",
          margin: "0 auto",
        };
      } else {
        return {};
      }
    },
  },
  watch: {
    search() {
      this.getItems();
    },
    pagination: {
      handler: function () {
        this.getItems();
      },
      deep: true,
    },
  },
  methods: {
    getFormatedDate(date) {
      return new Date(date).toLocaleDateString();
    },
    showPreview(item) {
      this.preview_data = item;
      this.show_preview = true;
    },
    getItems() {
      let _self = this;
      this.loading = "secondary";
      let url = parseParams(this.pageData);
      this.$axios
        .get("/admin/object/list?" + url)
        .then((response) => {
          _self.items = response.data.data;
          this.loading = false;
        })
        .catch(function () {
          this.loading = false;
        });
    },
    confirmDelete(item) {
      this.dialog = true;
      this.object_data = item;
    },
    deleteItem() {
      let _self = this;
      _self.loading = "secondary";
      this.$axios
        .post("/admin/object/delete", {
          object_id: this.object_id,
        })
        .then((res) => {
          if (res.status) {
            _self.getItems();
            _self.dialog = false;
            _self.object_id = "";
          }
          _self.loading = false;
        })
        .catch(function () {
          _self.loading = false;
        });
    },
  },
};
</script>
